import React, { useEffect } from "react"
import "./styles.css"
import { useForm } from "react-hook-form"
import {
  emailRegex,
  getQueryParams,
  urlRegex,
  useTranslation,
} from "../../utils"
import classNames from "classnames"
import { navigate } from "gatsby"
import { Link } from "gatsby-plugin-intl"
import { nanoid } from "nanoid"
import { toast } from "react-toastify"
import { useAnalytics } from "../../hooks/useTracking"

const ScoringForm = ({ location, smallForm }) => {
  const { t } = useTranslation()
  const { register, setValue, handleSubmit, errors, watch } = useForm()
  const watchFields = watch(["email", "siteUrl", "confirm"])
  const checkboxId = nanoid()
  const analytics = useAnalytics()

  useEffect(() => {
    if (errors.email) {
      if (errors.email.type === "required") toast(t("Please enter your email"))
      else toast(errors.email.message)
    }
    if (errors.siteUrl) {
      if (errors.siteUrl.type === "required")
        toast(t("Please enter the name of your site"))
      else toast(errors.siteUrl.message)
    }
    if (errors.confirm) {
      if (errors.confirm.type === "required") toast(t("Confirm privacy policy"))
      else toast(errors.confirm.message)
    }
  }, [errors])
  const onSubmit = ({ email, siteUrl, confirm }) => {
    analytics.getTheReport(email, siteUrl, confirm)
    navigate(
      "/scoring?url=" +
        encodeURIComponent(unescape(siteUrl)) +
        "&email=" +
        email
    )
  }

  useEffect(() => {
    if (location) {
      const url = getQueryParams(location.search)["url"]
      const email = getQueryParams(location.search)["email"]
      setValue("email", email)
      setValue("siteUrl", url)
    }
  }, [location])

  const handleChangeEmail = e => {
    const email = e.target.value
    if (email.match(emailRegex)) {
      setValue("email", email)
    }
  }

  const handleChangeSiteUrl = e => {
    const url = e.target.value
    if (url.match(urlRegex)) {
      setValue("siteUrl", url)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames("scoring-form", {
        "scoring-form--small": smallForm,
      })}
    >
      <span className="scoring-form__hint">
        {t("At least, 88% of websites have a serious vulnerability")}
      </span>
      <h3
        className={classNames("scoring-form__title", {
          "scoring-form__title--small": smallForm,
        })}
      >
        {smallForm
          ? t("Find out whether YOU ARE READY FOR cyber ATTACKS")
          : t("Get your free Cyber Risk Assessment")}
      </h3>
      <div className="scoring-form__form">
        <div className="scoring-form__inputs">
          <input
            name="email"
            onBlur={() => analytics.INPUT_EMAIL(watchFields.email)}
            onChange={handleChangeEmail}
            className={classNames("scoring-form__input", {
              "scoring-form__input--danger": errors.email,
            })}
            placeholder={t("Your company email")}
            aria-label="Scoring website input"
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
          />

          <input
            name="siteUrl"
            onBlur={() => {
              analytics.INPUT_DOMAIN(watchFields.siteUrl)
            }}
            onChange={handleChangeSiteUrl}
            className={classNames("scoring-form__input", {
              "scoring-form__input--danger": errors.siteUrl,
            })}
            placeholder={t("Your website")}
            aria-label="Scoring website input"
            ref={register({ required: true })}
          />

          <button type="submit" className="wt-button wt-button--warning">
            {t("Get the report")}
          </button>
        </div>

        <div className="scoring-form__check">
          <label
            className={classNames("scoring-form__checkbox", {
              "scoring-form__checkbox--danger": errors.confirm,
            })}
            htmlFor={"privacy-policy-" + checkboxId}
          >
            <input
              onChange={() => analytics.confirmPolicy(watchFields.confirm)}
              name="confirm"
              id={"privacy-policy-" + checkboxId}
              type="checkbox"
              ref={register({ required: true })}
            />
            <span className="scoring-form__checkbox-mark" />
            {t(
              "I confirm that I have read and understood the terms set out in "
            )}
            <Link onClick={analytics.clickGoToPP} to="/privacy" target="_blank">
              {t("Privacy Policy.")}
            </Link>
          </label>
        </div>
      </div>
    </form>
  )
}

export default ScoringForm
